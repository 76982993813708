import(/* webpackMode: "eager" */ "/home/crazyjane/_work/14/s/node_modules/.pnpm/next@14.2.18_@babel+core@7.25.8_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/14/s/pages/auth-page/src/app/components/Decoators/Decorators.module.css");
;
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/14/s/pages/auth-page/src/app/(pages)/layout.module.css");
;
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/14/s/pages/auth-page/src/assets/images/decorator_photo_1.jpg");
;
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/14/s/pages/auth-page/src/assets/images/decorator_photo_2.jpg");
;
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/14/s/pages/auth-page/src/assets/images/decorator_photo_3.jpg");
;
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/14/s/pages/auth-page/src/assets/images/decorator_photo_4.jpg");
